import React, {ReactNode, useContext, useState} from "react";
import Switch from "../../components/shared/Switch";

import { scheduleReactUpdate, useBind, useDerivedState, useSignal } from "../hooks";
import { alwaysTrue, emptyArray, emptyObject } from "../constants";

import {FormContext} from "./context";
import {FormState, FormSubmitCallback, useFormState, useNewForm} from "./formState";
import {FormField, useField, useFieldMonitor, useNewField} from "./formField";
import {useConversion} from "./convert";
import Input from "../../components/shared/Input";
import Dropdown from "../../components/shared/Dropdown";
import Textarea from 'components/shared/Textarea'
import {testLog} from "./util";
import Checkbox from "../../components/shared/Checkbox";
import Button from "../../components/shared/Button";
import { MultipleInput, MultipleInputProps } from "../../components/shared/MultipleInput/MultipleInput";
import { Tran, tran } from "utils/language";
import { regexTest } from "utils/globalFunctions";
import {DropdownOption, DropdownOptions} from "../../components/shared/Dropdown/types";
import QuillEditorLoader from "../../components/shared/QuillEditorLoader";
import { Dictionary } from "../types";
import ErrorBoundary from "../../components/shared/ErrorBoundary";
import { Icon, Message } from "../../components/shared";
import MaskedInput from "components/shared/MaskedInput";
import { MaskedInputProps } from "components/shared/MaskedInput/MaskedInput";
import { EnumDef } from "../../capi/enumDict";
import MaskedDateInput from "../../components/shared/MaskedDateInput";
import { MaskedDateInputProps } from "components/shared/MaskedDateInput/MaskedDateInput";

export {useFormState, useNewField, useField, useConversion};

const freeze = Object.freeze;

function extendContext(context: readonly FormState[], form: FormState): readonly FormState[] {
  return freeze([...context, form]);
}

/** Komponent formularza, w którym stan jest zarządzany wewnętrznie. */
export function Form<T>(props: { name: string, children: React.ReactNode, onSubmit: FormSubmitCallback<T>, submitData?: T, alwaysDirty?: boolean }) {
  const context = useContext(FormContext);
  
  const state = useNewForm(props.name)
    .onSubmit(props.onSubmit, props.submitData);
  
  state.alwaysDirty = !!props.alwaysDirty;
  
  const newContext = useDerivedState(extendContext, context, state);

  testLog(`Form isDirty=${state.isDirty} isValid=${state.isValid} isSubmitting=${state.isSubmitting}`);

  return <form onSubmit={state.submit} id={state.id}>
    <input type="submit" style={DISPLAY_NONE} onClick={state.submit}/>
    <FormContext.Provider value={newContext}>
      {props.children}
    </FormContext.Provider>
  </form>;
}

export type FormFieldProps<T> = {
  field?: FormField<T>
  name?: string
  form?: string | null
  initial?: T
  extra?: any
}

export type RequiredFormFieldProps<T> = {
  field: FormField<T>
  name?: string
  form?: string | null
  initial?: T
  extra?: any
} | {
  field?: FormField<T>
  name: string
  form?: string | null
  initial: T
  extra?: any
}

export type FormIsRequiredProps = {
  form?: string
}

/** Komponent formularza, do którego przekazujemy ręcznie utworzony stan. */
Form.External = function FormExternal(props: { state: FormState, children: React.ReactNode }) {
  const context = useContext(FormContext);
  const newContext = useDerivedState(extendContext, context, props.state);

  testLog(`Form isDirty=${props.state.isDirty} isValid=${props.state.isValid} isSubmitting=${props.state.isSubmitting}`);
  
  return <form onSubmit={props.state.submit} id={props.state.id}>
    <input type="submit" style={DISPLAY_NONE} onClick={props.state.submit}/>
    <FormContext.Provider value={newContext}>
      {props.children}
    </FormContext.Provider>
  </form>;
};

//

type FormInputProps0 = { invalidmessage?: React.ReactNode } & FormFieldProps<string>;
export type FormInputProps = FormInputProps0 & Omit<Input["_P"], keyof FormInputProps0>

/** Wrapper dla <Input/> podpięty pod formularz, tymczasowo dopóki nie napiszemy zamiennika (<Entry/>). */
Form.Input = function FormInput(props: FormInputProps) {
  const [visited, setVisited] = useState(false);
  const field = useField(props.field, props.name || "", props.form, props.initial || "", undefined, props.extra);
  const validate = useDerivedState(makeInputValidate, props.required, props.invalidmessage);
  const rawField = useConversion(field, validate, validate);
  
  const inputProps = {
    ...props,
    name: rawField.name,
    value: rawField.value,
    error: visited || props.disabled ? rawField.error : "",
    onChange: (value: any) => rawField.set(value),
    onFocus: (event: any) => {
      scheduleReactUpdate(() => rawField.wake());
      props.onFocus && props.onFocus(event);
      setVisited(true);
    },
    onBlur: props.onBlur,
    initial: undefined,
    field: undefined,
    form: field.formState && field.formState.id,
    invalidmessage: undefined,
  }
  
  testLog("Input", inputProps);
  
  return React.createElement(Input, inputProps);
};

const makeInputValidate = (required?: boolean, invalidmessage?: React.ReactNode) => (value: string): [string, (React.ReactNode | undefined)?] => {
  if (!required || value !== "")
    return [value];
  else
    return [value, invalidmessage || tran("error.fillField")];
}

//

// FIXME: to pole nie jest tylko do odczytu, a powinno!

Form.Constant = function FormConstant(props: FormInputProps) {
  const field = useField(props.field, props.name || "", props.form, props.initial || "", undefined, props.extra);
  const boundary = ErrorBoundary.useApi();
  React.useEffect(() => {
    if (field.error) {
      // czyścimy błąd, bo i tak się nie wyświetla w formularzu, zamiast tego damy dialog z komunikatem
      const error = field.error;
      field.set(field.value);
      boundary.softReportError(error);
    }
  }, [field.error]);
  return null;
}

type FormNumberInputProps = {
}

/** Z uwagi na statyczne typowanie ten rodzaj inputa może reprezentować TYLKO liczbę (nigdy brak liczby).
 *  Gdy wpisana wartość nie jest liczbą zwraca NaN (który wbrew nazwie jest liczbą :P). */
Form.NumberInput = function FormNumberInput(props: RequiredFormFieldProps<number> & Input["_P"] & FormNumberInputProps) {
  const modelField = useField<number>(props.field, props.name || "", props.form, props.initial, undefined, props.extra);
  const [number2string, string2number] = useDerivedState(makeNumberInputValidate, props.min, props.max);
  
  const stringField = useConversion(modelField, number2string, string2number);
  // console.log(`number name=${field.name} value=${field.value} initial=${field.initial}`);
  return React.createElement(Form.Input, { ...props, field: stringField, initial: undefined, min: undefined, max: undefined });
};

function makeNumberInputValidate(minimum: string | number | undefined, maximum: string | number | undefined): readonly [
  (value: number) => [string, (React.ReactNode | undefined)?],
  (value: string) => [number, (React.ReactNode | undefined)?]
] {
  const min = +minimum!;
  const max = +maximum!;
  
  return [number2string, string2number];
  
  function string2number(value: string): [number, (React.ReactNode | undefined)?] {
    const num = +value;
    return validateNumber(num);
  }
  
  function number2string(value: number): [string, (React.ReactNode | undefined)?] {
    const [v, e] = validateNumber(value);
    return [isNaN(v) ? "" : "" + v, e];
  }
  
  function validateNumber(num: number): [number, (React.ReactNode | undefined)?] {
    if (isNaN(num)) {
      return [NaN, tran("error.provideANumber")];
    }
    
    if (!isNaN(min) && num < min) {
      return [num, <Tran id="error.numberMin" search="#" replace={min}/>]
    }
    
    if (!isNaN(max) && num > max) {
      return [num, <Tran id="error.numberMax" search="#" replace={max}/>]
    }
    
    return [num]
  }
}

function makeOptionalNumberInputValidate(min: string | number | undefined, max: string | number | undefined): readonly [
  (value: number | undefined) => [string, (React.ReactNode | undefined)?],
  (value: string) => [number | undefined, (React.ReactNode | undefined)?]
] {
  const [number2string, string2number] = makeNumberInputValidate(min, max);
  return [
    (value: number | undefined) => {
      if (isNaN(value!))
        return [""];
      else
        return number2string(value!);
    },
    (value: string) => {
      if (value === "")
        return [undefined];
      else
        return string2number(value);
    },
  ]
}

/** Ten rodzaj inputa może reprezentować liczbę lub undefined */
Form.OptionalNumberInput = function FormOptionalNumberInput(props: FormFieldProps<number | undefined> & Input["_P"]) {
  const modelField = useField<number | undefined>(props.field, props.name || "", props.form, props.initial, undefined, props.extra);
  const [number2string, string2number] = useDerivedState(makeOptionalNumberInputValidate, props.min, props.max);
  const stringField = useConversion(modelField, number2string, string2number);
  // console.log(`number name=${field.name} value=${field.value} initial=${field.initial}`);
  return React.createElement(Form.Input, { ...props, field: stringField, initial: undefined, min: undefined, max: undefined });
};

//

type PatternInputProps = { required?: boolean, pattern: RegExp | string, invalidmessage?: React.ReactNode };

Form.PatternInput = function FormPatternInput(props: PatternInputProps & FormFieldProps<string> & Omit<Input["_P"], keyof PatternInputProps>) {
  const validField = useField(props.field, props.name || "", props.form, props.initial || "", undefined, props.extra);
  const validate = useDerivedState(makePatternInputValidate, props.pattern, props.invalidmessage);
  const rawField = useConversion(validField, validate, validate);
  
  return React.createElement(Form.Input, { ...props, field: rawField, pattern: undefined, initial: undefined, invalidmessage: undefined });
}

const makePatternInputValidate = (pat: RegExp | string, invalidmessage?: React.ReactNode) => (value: string): [string, (React.ReactNode | undefined)?] => {
  let pattern: RegExp = pat as any;
  
  if (!pat)
    return [value];
  
  if (typeof pat === "string")
    pattern = new RegExp(pat);
  
  if (value === "" || regexTest(pattern, value))
    return [value];
  else
    return [value, invalidmessage || tran("error.invalidFormat")];
}

//

/** Ten rodzaj inputa pozwala przekazać funkcję walidującą, np. gdy nie chcemy użyć do walidacji regexa (jak w `FormPatternInput`) */
type ValidatedInputProps = { validationFunc: (val: any) => boolean | undefined, required?: boolean, invalidmessage?: React.ReactNode };

Form.ValidatedInput = function FormValidatedInput(props: ValidatedInputProps & FormFieldProps<string> & Omit<Input["_P"], keyof ValidatedInputProps>) {
  const validField = useField(props.field, props.name || "", props.form, props.initial || "", undefined, props.extra);
  const validate = useDerivedState(makeValidatedInputValidate, props.validationFunc, props.invalidmessage);
  const rawField = useConversion(validField, validate, validate);
  
  return React.createElement(Form.Input, { ...props, field: rawField, initial: undefined, invalidmessage: undefined });
}

const makeValidatedInputValidate = (validationFunc: (val: any) => boolean | undefined, invalidmessage?: React.ReactNode) => (value: string): [string, (React.ReactNode | undefined)?] => {
  if (value === "" || validationFunc(value))
    return [value];
  else
    return [value, invalidmessage || tran("error.invalidFormat")];
}

//

type FormDropdownProps0<T> = {
  optionFilter?: (option: T) => boolean
  allowOthers?: boolean
} & RequiredFormFieldProps<T>
export type FormDropdownProps<T> = FormDropdownProps0<T> & Omit<Dropdown<T>["_P"], keyof FormDropdownProps0<T>> & {
  required?: boolean;
  invalidmessage?: React.ReactNode;
}

/** Wrapper dla <Dropdown/> podpięty pod formularz, tymczasowo dopóki nie napiszemy zamiennika. */
Form.Dropdown = function FormDropdown<T = any>(props: FormDropdownProps<T>) {
  const [visited, setVisited] = useState(false);
  const field = useField<T>(props.field, props.name || "", props.form, props.initial, undefined, props.extra);
  const validate = useDerivedState(makeDropdownValidate<T>, props.required, props.invalidmessage);
  const rawField = useConversion(field, validate, validate);

  const optionFilter = props.optionFilter || alwaysTrue;
  const options0 = useDerivedState(deriveOptionsForDropdown, props.options, optionFilter);
  
  // część tej logiki pewnie powinna być w Dropdown
  
  const value = rawField.value;
  let options: typeof options0;
  let option: DropdownOption<T>;
  
  // filtrujemy opcje wg. podanej funkcji optionFilter
  for (let i in options0) {
    const opt = options0[i] as DropdownOption<T>;
    if (opt.hasOwnProperty("value") && opt.value === value) {
      option = opt;
      options = options0;
      break;
    }
  }
  
  // nie znaleźliśmy value pośród opcji, to dodajemy
  // @ts-ignore
  if (!option || !options) {
    option = { value, text: typeof value === "object" ? "???" : ("" + value), disabled: !props.allowOthers };
    options = [option, ...options0];
  }
  
  React.useEffect(() => {
    if (option.disabled)
      field.set(option.value as T, tran("error.selectOption"));
    else
      field.set(option.value as T);
  }, [option.disabled]);
  
  const dropdownProps = {
    ...props,
    name: rawField.name,
    value: value,
    error: visited || props.disabled ? rawField.error : "",
    options: options,
    onChange: (value: T) => { rawField.set(value); setVisited(true); },
    initial: undefined,
    field: undefined,
    allowOthers: undefined,
    optionFilter: undefined,
    form: field.formState && field.formState.id,
  };

  testLog("Dropdown", dropdownProps);
  
  return <Dropdown<T> {...dropdownProps}/>;
};

const makeDropdownValidate = <T,>(required?: boolean, invalidmessage?: React.ReactNode) => (value: T): [T, (React.ReactNode | undefined)?] => {
  if (!required || (value !== undefined && value !== ""))
    return [value];
  else 
    return [value, invalidmessage || tran("error.fillField")];
}

function deriveOptionsForDropdown<T>(options: DropdownOptions<T>, optionFilter: (option: T) => boolean) {
  const result = [...options] as DropdownOptions<T>;
  for (let i in options) {
    const opt = options[i] as DropdownOption<T>;
    if (opt.hasOwnProperty("value")) {
      const ok = optionFilter(opt.value as T); /** undefined nie wystąpi, chyba że T zawiera undefined */
      if (!ok)
        // @ts-ignore
        result[i] = { ...opt, disabled: true }
    }
  }
  return result;
}

//

/** Wrapper dla <Form.Dropdown/>, który automatycznie robi listę opcji z enuma. */
Form.EnumDropdown = function FormEnumDropdown(props: Omit<FormDropdownProps<string>, "options"> & { enum: EnumDef }) {
  const def = props.enum;
  let options: readonly DropdownOption<string>[] = useDerivedState(deriveOptionsForEnum, def);
  
  if (def.ordered === emptyArray) // zanim nastąpi załadowanie enuma z serwera
    options = [{
      value: props.initial,
      text: loadingOption,
      disabled: true
    }];
  
  const newProps = {
    ...props,
    enum: undefined,
    options,
  }
  
  //@ts-ignore
  return <Form.Dropdown<string> {...newProps}/>
}

function deriveOptionsForEnum(def: EnumDef) {
  return def.ordered.map(val => ({
    value: val.key,
    text: val.desc || val.key,
  }));
};

const loadingOption = <i>{tran("unique.loading")} ...</i>;

//

function derivePropsForCheckbox(field: FormField<boolean>, props: any, toggle: Function) {
  return {
    ...props,
    name: field.name,
    checked: field.value,
    onClick: toggle,
    initial: undefined,
    form: undefined,
  };
}

function toggleCheckbox(field: FormField<boolean>) {
  field.set(!field.value);
}

/** Wrapper dla <Checkbox/> podpięty pod formularz, tymczasowo dopóki nie napiszemy zamiennika. */
Form.Checkbox = function FormCheckbox(props: RequiredFormFieldProps<boolean> & Checkbox["_P"]) {
  const field = useField<boolean>(props.field, props.name || "", props.form, props.initial);
  const toggle = useBind(toggleCheckbox, field);
  const checkboxProps = useDerivedState(derivePropsForCheckbox, field, props, toggle);

  testLog("Checkbox", checkboxProps);
  
  return React.createElement(Checkbox, checkboxProps);
};

Form.Switch = function FormSwitch(props: RequiredFormFieldProps<boolean>) {
  const field = useField<boolean>(props.field, props.name || "", props.form, props.initial);
  const toggle = useBind(toggleCheckbox, field).preventDefault();
  const switchProps = {
    isOn: field.value,
    handleToggle: toggle,
  };

  return React.createElement(Switch, switchProps);
};

//

Form.MaskedInput = function FormMaskedInput(props: MaskedInputProps & FormFieldProps<string> & {invalidMessage?: React.ReactNode }) {
  const [visited, setVisited] = useState(false);
  const field = useField(props.field, props.name || "", props.form, props.initial || "");
  const validate = useDerivedState(makeMaskedInputValidate, props.required, props.mask, props.invalidMessage);
  const rawField = useConversion(field, validate, validate);

  const maskedInputProps = {
    ...props,
    name: rawField.name,
    value: rawField.value,
    error: visited ? rawField.error : "",
    onChange: (value: any) => rawField.set(value),
    onFocus: (event: any) => {
      scheduleReactUpdate(() => rawField.wake());
      props.onFocus && props.onFocus(event);
      setVisited(true);
    },
    onBlur: props.onBlur,
    initial: undefined,
    field: undefined,
    form: field.formState && field.formState.id,
  }

  return <MaskedInput {...maskedInputProps} />
}

const makeMaskedInputValidate = (required: boolean | undefined, mask: string, invalidMessage?: React.ReactNode) => (value: string): [string, (React.ReactNode | undefined)?] => {
  if (value.includes("_") || mask.length !== value.length)
    return [value, invalidMessage ?? tran("gaps.incompleteCopyId")];
  else if (!required || value !== "")
    return [value];
  else
    return [value, tran("error.fillField")];
}

type TextareaProps = { minLength: number, invalidmessage?: React.ReactNode, required: boolean };

Form.Textarea = function FormTextarea(props: TextareaProps & FormFieldProps<string> & Textarea["_P"]){
  const [visited, setVisited] = useState(false);
  const field = useField(props.field, props.name || "", props.form, props.initial || "", undefined, props.extra);
  const validate = useDerivedState(makeTextareaValid, props.required, props.minLength, props.invalidmessage);
  const rawField = useConversion(field, validate, validate);

  const textareaProps = {
    ...props, 
    name: rawField.name,
    value: rawField.value,
    onChange: (value: any) => rawField.set(value),
    onFocus: () => {
      scheduleReactUpdate(() => rawField.wake());
      setVisited(true);
    },
    field: undefined,
    form: field.formState && field.formState.id,
    error: visited ? rawField.error : "",
    invalidmessage: undefined,
  }

  return <Textarea {...textareaProps} />
}

const makeTextareaValid = (required: boolean, minLength: number = 1, invalidmessage?: React.ReactNode) => (value: string): [string, (React.ReactNode | undefined)?] => {
  if(required){
    if(value && value.length >= minLength) {
      return [value]
    } else {
      // TODO: domyślny komunikat dla niespełnionej liczby znaków
      return [value, invalidmessage || tran("error.fillField")]
    }
  } else {
    return [value]
  }
} 

function derivePropsForMultipleInput(field: FormField<readonly string[]>, props: any) {
  return {
    ...props,
    value: field.value || props.placeholder, // ! Tymczasowo fallback do placeholderów, do czasu aż nie znajdziemy lepszego rozwiązania.
    onChange: (value: any) => field.set(value),
    onFocus: () => {
      scheduleReactUpdate(() => field.wake());
    },
    initial: undefined,
    form: field.formState && field.formState.id,
  }
}

// TODO: obsługa required
Form.MultipleInput = function FormMultipleInput(props: RequiredFormFieldProps<readonly string[]> & Omit<MultipleInputProps, "value">) {
  const field = useField<readonly string[]>(props.field, props.name || "", props.form, props.initial, undefined, props.extra);
  const multipleInputProps = useDerivedState(derivePropsForMultipleInput, field, props);

  testLog("MultipleInput", multipleInputProps);

  return React.createElement(MultipleInput, multipleInputProps);
}

//

Form.MaskedDateInput = function FormMaskedDateInput(props: FormFieldProps<string> & Omit<MaskedDateInputProps, "value"> & {invalidMessage?: React.ReactNode }) {
  const [visited, setVisited] = useState(false);

  const field = useNewField(props.name || "", props.form, props.initial || "");
  const validate = useDerivedState(makeMaskedInputValidate, props.required, props.mask || "9999-99-99", props.invalidMessage);
  const rawField = useConversion(field, validate, validate);
  
  const maskedDateProps = {
    ...props,
    error: visited ? rawField.error : "",
    name: rawField.name,
    value: rawField.value,
    field: undefined,
    onChange: (value: string) => rawField.set(value),
    onFocus: (event: any) => {
      scheduleReactUpdate(() => rawField.wake());
      setVisited(true);
    },
    form: field.formState && field.formState.id,
  }

  return React.createElement(MaskedDateInput, maskedDateProps);
}

/** Wrapper dla <Button/> podpięty pod formularz, tymczasowo dopóki nie napiszemy zamiennika. */
Form.Submit = React.memo(function FormSubmit(props: { [key: string]: any; form?: string, submitId?: string }) { // TODO: propsy Button
  const form = useFormState(props.form);
  
  const submit = props.submitId
    ? form.submitWithId.bind(null, props.submitId)
    : form.submit;
  
  return <Button
    {...props}
    loading={props.loading || form.isSubmitting}
    disabled={props.disabled || !form.isDirty}
    onClick={submit}
    form={form.id}
    color={form.isValid ? props.color : "error"}
  />;
});

//

/** Wrapper dla <Button/>, który przywraca wartości początkowe formularza. */
Form.Reset = function FormReset(props: { [key: string]: any; form?: string }) { // TODO: propsy Button
  const form = useFormState(props.form);
  
  return <Button
    preset="cancel"
    {...props}
    disabled={props.disabled || !form.isDirty}
    onClick={(event: any) => { event.preventDefault(); form.reset(); }}
    form={form.id}
    type="reset"
  />;
};

//

Form.HTMLEditor = function FormHTMLEditor(props: FormFieldProps<string> & { className?: string, options?: Dictionary }) {
  const [visited, setVisited] = useState(false);
  const field = useField(props.field, props.name || "", props.form, props.initial || "", undefined, props.extra);
  
  // chcielibyśmy ograniczyć częstość aktualizacji, ale react-quill nam nie da...
  //const setDelayed = useSteady(field.setter, 500); 
  
  const quillProps = {
    options: emptyObject,    
    ...props,
    value: field.value,
    onChange,
    onFocus: () => { setVisited(true) },
    form: undefined,
    field: undefined,
    initial: undefined,
    name: undefined,
    extra: undefined,
  }
  
  return <>
    <QuillEditorLoader {...quillProps} />
    
    {visited && field.error
      ? <p className="custom-input__error-msg">{field.error}</p>
      : null}
  </>;
  
  function onChange(content: string, delta: any, source: any) {
    field.set(content);
  }
}

//

/** Warunkowe renderowanie na podstawie wartości pola formularza. */
Form.MountIf = function FormMountIf(props: { form?: string, on: string, when: (value: any) => boolean, children?: React.ReactNode }) {
  const field = useFieldMonitor<any>(props.on, props.form);
  
  if (field && field.isValid && props.when(field.value))
    return props.children as React.ReactElement<any>;
  
  return null;
};

const DISPLAY_NONE = Object.freeze({ display: "none" });
Form.ShowIf = function FormShowIf(props: { form?: string, on: string, when: (value: any) => boolean, children?: React.ReactNode }) {
  const field = useFieldMonitor<any>(props.on, props.form);
  
  if (!field)
    return null;
  
  const style = field.isValid && props.when(field.value)
    ? undefined
    : DISPLAY_NONE;
    
  return <span style={style}>{props.children}</span>;
};

//

Form.ChangeWarning = function ChangeWarning(props: { form?: string, on: string, children?: React.ReactNode }) {
  const field = useFieldMonitor(props.on, props.form);
  
  if (field?.isDirty)
    return <Message type="warning" content={props.children} />
  return null;
};
