import React from "react";

const Divider: React.FunctionComponent<{
  text?: React.ReactNode;
  textStyle?: any;
  style?: any;
  className?: string;
}> = ({ text, style, className = "", textStyle }) => {
  if (text) {
    return (
      <div className={`divider-text ${className}`} style={style}>
        <div />
        <p style={textStyle}>{text}</p>
        <div />
      </div>
    );
  } else {
    return <div className={`divider ${className}`} style={style} />;
  }
};

export default Divider;
